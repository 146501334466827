<template>
  <footer
    class="app-footer"
    :class="[`app-footer--theme-${theme}`]">
    <div :class="[insert ? 'app-footer__container' : 'container']">
      <div class="app-footer__inner">
        <div class="app-footer__info">
          <div class="app-footer__social social">
            <h5>Мы в соцсетях</h5>
            <div class="social__list">
              <a
                href="https://vk.com/nticontest"
                class="social__item"
                target="_blank"
                rel="noopener noreferrer">
                <BaseIcon
                  glyph="vk"
                  width="18px" />
              </a>
              <a
                href="https://t.me/nto_olympiada"
                class="social__item"
                target="_blank"
                rel="noopener noreferrer">
                <BaseIcon
                  glyph="telegram"
                  width="18px" />
              </a>
              <!-- <a
                href="https://www.facebook.com/nticontest/"
                class="social__item"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BaseIcon glyph="fb" width="18px" />
              </a>
              <a
                href="https://www.instagram.com/nti_contest/"
                class="social__item"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BaseIcon glyph="instagram" width="18px" />
              </a> -->
            </div>
          </div>
          <div class="app-footer__support">
            <h5>Служба поддержки</h5>
            <a
              class="app-footer__support-mail"
              :href="`mailto:${$store.state.supportEmail}`"
              >{{ $store.state.supportEmail }}</a
            >
          </div>
          <div class="sb-box">
            <BviButton theme="blue" />
          </div>

          <div class="app-footer__links">
            <slot name="links"></slot>
          </div>
        </div>
        <a
          href="/"
          target="_blank"
          rel="noopener noreferrer"
          class="app-footer__logo">
          <img
            v-if="theme === 'light'"
            src="@/assets/images/logo_new_black.svg"
            alt="Национальная технологическая олимпиада" />
          <img
            v-else
            src="@/assets/images/logo_new.svg"
            alt="Национальная технологическая олимпиада" />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import BviButton from "@/components/BviButton.vue";

export default {
  name: "AppFooter",
  components: { BviButton },
  props: {
    theme: {
      type: String,
      default: "dark", // light, transparent
    },
    insert: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="less">
.app-footer {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 40px;
  border-top: 1px solid @lp-outline-color;
  background-color: lighten(@bg-dark, 2%);
  color: @lp-base-color;

  &__container {
    max-width: 86%;
    padding: 0 20px;
    margin: 0 auto;
  }

  &__logo {
    width: 250px;
    max-width: 250px;
    aspect-ratio: ~"300/105";
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  a {
    text-decoration: none;
    color: @lp-base-color;
  }

  &__info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 700px;
    margin-right: 30px;
  }

  &__social {
    margin-right: 50px;
  }

  &__support {
    display: flex;
    flex-direction: column;

    &-mail {
      font-size: 22px;
      font-weight: 600;
      line-height: 1;
      text-transform: uppercase;
      padding: 15px 0px;
      color: @lp-base-color;
      border-radius: 50px;
      transition: color 0.3s;

      &:hover {
        color: @link-blue;
      }
    }
  }

  &__links {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    margin-top: 24px;
    margin-left: -10px;
    margin-right: -10px;

    a {
      margin: 0 10px;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  &--theme-light {
    background-color: lighten(@bg-color, 2%);
    border-top: none;
    color: @grey-blue;

    a {
      color: @grey-blue;
    }
  }

  &--theme-light &__support-mail {
    color: @grey-blue;

    &:hover {
      color: @link-blue;
    }
  }

  &--theme-transparent {
    background-color: transparent;
    border-top: none;
    color: @grey-blue;

    a {
      color: @grey-blue;
    }
  }

  @media (max-width: @screen-lg) {
    &__container {
      max-width: 100%;
    }
  }

  @media (max-width: @screen-sm) {
    &__info {
      display: block;

      flex: 0 1 auto;
    }
    &__support {
      margin-top: 30px;
    }

    &__links {
      display: block;
      a {
        display: block;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  @media (max-width: @screen-xs) {
    &__social {
      margin-right: 0;
    }

    &__support {
      &-mail {
        padding: 10px 0;
        font-size: 20px;
      }
    }

    &__links {
      font-size: 14px;
      padding-right: 110px;
    }

    &__inner {
      position: relative;
      flex-wrap: wrap;
    }

    &__info {
      margin-right: 0;
      width: 100%;
      flex-shrink: 0;
    }

    &__logo {
      margin-top: 20px;
      max-width: 250px;
    }
  }
  @media (max-width: @screen-xs) {
    &__logo {
      width: 300px;
    }
  }
}
.social {
  &__list {
    display: flex;
    margin-top: 17px;
  }

  &__item {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 18px;
    transition: color 0.3s;
    margin-right: 26px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: @link-blue;
    }

    &::before {
      content: "";
      width: 32px;
      height: 32px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.sb-box {
  margin-left: auto;
  display: flex;
  align-items: flex-start;
  align-self: flex-start;
  margin-top: -4px;

  & button {
    align-self: flex-start;
  }

  @media screen and (max-width: @screen-lg) {
    & .bvi-text {
      display: none;
    }
  }

  @media screen and (max-width: @screen-sm) {
    & .bvi-text {
      display: block;
    }
  }
}
</style>
